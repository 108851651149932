@font-face {
  font-family: 'Barlow';
  font-weight: normal;
  font-style : regular;
  src: url('../public/files/project/fonts/Barlow-Regular.woff') format('woff'),
       url('../public/files/project/fonts/Barlow-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Barlow-SemiBold';
  font-weight: bold;
  font-style : regular;
  src: url('../public/files/project/fonts/Barlow-SemiBold.woff') format('woff'),
       url('../public/files/project/fonts/Barlow-SemiBold.ttf') format('ttf');
}
@font-face {
  font-family: 'BarlowCondensed-Bold';
  font-weight: bold;
  font-style : normal;
  src: url('../public/files/project/fonts/BarlowCondensed-Bold.woff') format('woff'),
      url('../public/files/project/fonts/BarlowCondensed-Bold.ttf') format('ttf');
}
@font-face {
  font-family: 'BarlowCondensed-Regular';
  font-weight: regular;
  font-style : regular;
  src: url('../public/files/project/fonts/BarlowCondensed-Regular.woff') format('woff'),
     url('../public/files/project/fonts/BarlowCondensed-Regular.ttf') format('ttf');
}
@font-face {
  font-family: 'project';
  font-weight: normal;
  font-style : normal;
  src: url('../public/files/project/fonts/project.woff') format('woff'),
       url('../public/files/project/fonts/project.ttf') format('ttf');
}

.title-font,
.content-font {
  font-family: 'Barlow';
}

.app-toolbar .content-font, .home-tile {
  font-family: 'Barlow';
}

.icon-font {
  font-family: 'project';
}

input, .generic-input, ::-webkit-input-placeholder {
  @extend .content-font;
}