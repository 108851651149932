
@import "../../../data/config-scss/variables.scss";

div.save-contact-btn {
    margin   : .1em .3em 0;
    font-size: 1.8em;
    color    : $shareBtnColor;
    cursor   : pointer;
    -webkit-tap-highlight-color: transparent;
}

.is-mobile div.save-contact-btn {
    padding-top: 0;
}

@media (min-width:768px) {
    div.save-contact-btn {
        font-size: 1em;
    }
}