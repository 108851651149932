.paper-title {
    padding: 0 1.1em;

    h2 {
        font-size: 1.1em !important;
        font-weight: bold;
    }
}

.paper-text {
    padding: 0 1.1em;
    text-align:justify;
    ul {
    	text-align:left;
    }
}

.paper-authors {
    font-size: 18px;
}
